import React, { useState } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'

import { DesktopSidebar, MobileSidebar, Main } from './layout'

import { useUI } from './shared/hooks/'
import { HomePattern } from './layout/HomePattern'

const App = () => {
  const { isMobile } = useUI()
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)

  return (
    <>
      <div id='home' className='relative bg-gray-50 overflow-hidden'>
        <HomePattern />
        <main className='relative pt-6 pb-12 sm:pb-16 md:pb-40 lg:pb-40 xl:pb-40'>
          <DesktopSidebar />

          {isMobile && (
            <MobileSidebar
              isOpen={isSidebarOpen}
              toggleSidebar={() => setIsSidebarOpen((v) => !v)}
            />
          )}

          <Main />
        </main>
      </div>

      <section className='z-100 bg-blue-900 h-full' id='who-we-are'>
        <div className='h-16' />
        <div className='mx-auto max-w-screen-xl px-4 py-20 sm:px-6'>
          <div className='text-center'>
            <h3 className='pb-2 text-3xl tracking-tight leading-10 font-extrabold text-white sm:text-4xl sm:leading-none md:text-5xl'>
              Who We Are
              <br className='xl:hidden' />
            </h3>

            <p className='mt-3 max-w-md mx-auto text-base text-gray-200 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl text-justify'>
              Spinnaker Financial Services is a CPA firm licensed in Missouri.
              We focus on tax services to individuals and business clients. Our
              goal is to objectively serve as our client’s advocate in a way
              that feels personalized but with big firm professionalism. We
              value a team effort with you and your trusted financial advisor in
              working toward a coordinated approach that promotes convenience
              for our clients while providing the highest level of client
              services possible.
            </p>
          </div>
        </div>
        <div className='h-16' />
      </section>

      <section className='bg-white overflow-hidden' id='our-services'>
        <div className='h-16' />
        <div className='relative max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:px-8'>
          <svg
            className='absolute top-0 left-full transform -translate-x-1/2 -translate-y-3/4 lg:left-auto lg:right-full lg:translate-x-2/3 lg:translate-y-1/4'
            width='404'
            height='784'
            fill='none'
            viewBox='0 0 404 784'>
            <defs>
              <pattern
                id='8b1b5f72-e944-4457-af67-0c6d15a99f38'
                x='0'
                y='0'
                width='20'
                height='20'
                patternUnits='userSpaceOnUse'>
                <rect
                  x='0'
                  y='0'
                  width='4'
                  height='4'
                  className='text-gray-200'
                  fill='currentColor'
                />
              </pattern>
            </defs>
            <rect
              width='404'
              height='784'
              fill='url(#8b1b5f72-e944-4457-af67-0c6d15a99f38)'
            />
          </svg>

          <div className='relative lg:grid lg:grid-cols-3 lg:col-gap-8'>
            <div className='lg:col-span-1'>
              <h3 className='text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10'>
                Our Primary Services for Individuals and Businesses
              </h3>
            </div>
            <div className='mt-10 sm:grid sm:grid-cols-2 sm:col-gap-8 sm:row-gap-10 lg:col-span-2 lg:mt-0'>
              <div>
                <div className='flex items-center justify-center h-12 w-12 rounded-md bg-blue-700 text-white'>
                  <svg
                    className='h-6 w-6'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'>
                    <path
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      d='M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9'
                    />
                  </svg>
                </div>
                <div className='mt-5'>
                  <h4 className='text-lg leading-6 font-medium text-gray-900'>
                    Tax Compliance
                  </h4>
                  <p className='mt-2 text-base leading-6 text-gray-500'>
                    Federal and State tax return preparation for individuals,
                    estates and trusts, corporations, partnerships and LLCs
                  </p>
                </div>
              </div>
              <div className='mt-10 sm:mt-0'>
                <div className='flex items-center justify-center h-12 w-12 rounded-md bg-blue-700 text-white'>
                  <svg
                    className='h-6 w-6'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'>
                    <path
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      d='M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3'
                    />
                  </svg>
                </div>
                <div className='mt-5'>
                  <h4 className='text-lg leading-6 font-medium text-gray-900'>
                    Tax Planning
                  </h4>
                  <p className='mt-2 text-base leading-6 text-gray-500'>
                    Helping you know if you will owe tax in advance so you can
                    make adjustments to withholding or develop strategies for
                    better decisions.
                  </p>
                </div>
              </div>
              <div className='mt-10 sm:mt-0'>
                <div className='flex items-center justify-center h-12 w-12 rounded-md bg-blue-700 text-white'>
                  <svg
                    className='h-6 w-6'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'>
                    <path
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      d='M13 10V3L4 14h7v7l9-11h-7z'
                    />
                  </svg>
                </div>
                <div className='mt-5'>
                  <h4 className='text-lg leading-6 font-medium text-gray-900'>
                    Small Business Bookkeeping and Payroll Assistance
                  </h4>
                  <p className='mt-2 text-base leading-6 text-gray-500'>
                    As certified QuickBooks ProAdvisors, we can help save you
                    money on monthly subscriptions
                  </p>
                </div>
              </div>
              <div className='mt-10 sm:mt-0'>
                <div className='flex items-center justify-center h-12 w-12 rounded-md bg-blue-700 text-white'>
                  <svg
                    className='h-6 w-6'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'>
                    <path
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      d='M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z'
                    />
                  </svg>
                </div>
                <div className='mt-5'>
                  <h4 className='text-lg leading-6 font-medium text-gray-900'>
                    IRS Representation for audits
                  </h4>
                  <p className='mt-2 text-base leading-6 text-gray-500'>
                    Our clients don’t have to face tax notices alone. We respond
                    to simple requests for more info or we can meet with agents
                    for you, if that becomes necessary.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='h-16' />
      </section>

      <section className='relative py-12 bg-gray-50 overflow-hidden md:py-20 lg:py-24'>
        <HomePattern />
        <div className='relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8'>
          <div className='md:flex-shrink-0'>
            <img
              className='mx-auto h-14 rounded-full'
              src={require('../assets/alliance-marketing.jpg')}
              alt=''
            />
          </div>

          <div className='relative'>
            <blockquote className='mt-8'>
              <div className='max-w-3xl mx-auto text-center text-2xl leading-9 font-medium text-gray-900'>
                <p>
                  &ldquo;Barb at Spinnaker has been a great help, we are very
                  happy with the services she has provided. We really appreciate
                  how she has supported us through this Covid disaster.&rdquo;
                </p>
              </div>
              <footer className='mt-8'>
                <div className='md:flex md:items-center md:justify-center'>
                  {/* <div className='md:flex-shrink-0'>
                    <img
                      className='mx-auto h-10 w-10 rounded-full'
                      src='https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
                      alt=''
                    />
                  </div> */}
                  <div className='mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center'>
                    <div className='text-base leading-6 font-medium text-gray-900'>
                      Robert Espenschied
                    </div>

                    <svg
                      className='hidden md:block mx-1 h-5 w-5 text-blue-800'
                      fill='currentColor'
                      viewBox='0 0 20 20'>
                      <path d='M11 0h3L9 20H6l5-20z' />
                    </svg>

                    <div className='text-base leading-6 font-medium text-gray-500'>
                      CEO, Alliance Marketing
                    </div>
                  </div>
                </div>
              </footer>
            </blockquote>
          </div>
        </div>
      </section>

      <ManageDocuments />

      <div className='bg-white'>
        <div className='max-w-screen-xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8'>
          <div>
            <p className='font-bold text-black'>
              Spinnaker Financial Services, LLC
            </p>
            <p className='text-md text-gray-500'>Mailing Address</p>
            <p className='text-md text-gray-500'>14248F Manchester Rd #110</p>
            <p className='text-md text-gray-500'>Manchester, MO 63011</p>
          </div>
          <div className='mt-8 md:mt-0 md:order-1'>
            <p className='text-center text-base leading-6 text-gray-400'>
              &copy; Spinnaker Financial Services, LLC. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default App

const faqs = [
  {
    question: 'Client Portal',
    answer: (
      <p>
        As of 2022, there is a new secure online
        <a
          target='_blank'
          href='https://clientaxcess.com'
          className='font-medium text-blue-600 hover:text-blue-500'>
          {' '}
          client portal{' '}
        </a>
        you can use to store your documents and copies of your tax returns for
        easy access at any time. This is a safe way for you to provide your tax
        information to us electronically. Please contact us if you would like an
        invitation to join.
      </p>
    ),
  },
  {
    question: 'Securely Send Us Your Documents Online',
    answer: (
      <p>
        If you do not want to use the portal, you can upload documents (or even
        photos of documents) to us securely{' '}
        <a
          target='_blank'
          href='https://www.clientaxcess.com/sharesafe/#/1wNxd1'
          className='font-medium text-blue-600 hover:text-blue-500'>
          using this link.
        </a>{' '}
        This does not allow you to see document(use the portal for that), it
        only allows you to send them.
      </p>
    ),
  },
  {
    question: 'Drop Off Your Documents',
    answer:
      'Our address at the local UPS Store is open for you to walk-in and drop off your documents with no need for an appointment.  The store is normally open 8a – 6:30p Monday - Friday, or 9a – 3p on Saturday.  (Closed on Sunday).  Our primary contact there is Adele.  Call (636) 207-8771 to make sure they are open if inclement weather.',
  },
]

export const ManageDocuments = () => {
  return (
    <section className='bg-white' id='manage-documents'>
      <div className='max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-20 lg:px-8'>
        <div className='lg:grid lg:grid-cols-3 lg:gap-8'>
          <div>
            <h2 className='text-3xl font-extrabold text-gray-900'>
              Manage Documents
            </h2>
            <p className='mt-4 text-lg text-gray-500'>
              Can’t find the answer you’re looking for? Reach out to our{' '}
              <a
                href='mailto:contact@spinnakerfs.com'
                className='font-medium text-blue-600 hover:text-blue-500'>
                customer support
              </a>{' '}
              team.
            </p>
          </div>
          <div className='mt-12 lg:mt-0 lg:col-span-2'>
            <dl className='space-y-12'>
              {faqs.map((faq) => (
                <div key={faq.question}>
                  <dt className='text-lg leading-6 font-medium text-gray-900'>
                    {faq.question}
                  </dt>
                  <dd className='mt-2 text-base text-gray-500'>{faq.answer}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </section>
  )
}
