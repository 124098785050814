import React, { useState } from 'react'
import { Popup, Modal } from '../shared/components'

export const Main: FC<{}> = ({ children }) => {
  const [showPortalModal, setShowPortalModal] = useState(false)

  return (
    <div className='mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 xl:mt-28'>
      <Modal
        className='max-w-xl'
        isVisible={showPortalModal}
        onClose={() => setShowPortalModal(false)}>
        <h3 className='text-2xl text-bold border-bottom border-bottom-gray-600 w-full'>
          Our Client Portal
        </h3>

        <p className='text-gray-600 py-2'>
          We invite our clients to share sensitive information through our
          secure portal. In order to use the portal, you must have received
          email approval from one of our accountants.
        </p>

        <div className='mt-3 rounded-md shadow sm:mt-0 sm:ml-3'>
          <a
            target='_blank'
            href='https://clientaxcess.com'
            className='w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-blue-800 hover:bg-blue-500 focus:outline-none focus:border-blue-900 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10'>
            Client Portal
          </a>
        </div>
      </Modal>

      <div className='text-center'>
        <h2 className='text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl'>
          Spinnaker
          <br className='xl:hidden' />
          <span className='text-blue-900'> Financial Services</span>
        </h2>

        <p className='mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl'>
          Whether your immediate concerns involve taxes, payroll, bookkeeping,
          or audits, we have the answers to your accounting needs.
        </p>

        <div className='mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8'>
          <div className='rounded-md shadow'>
            <a
              href='mailto:contact@spinnakerfs.com'
              className='w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-blue-900 hover:bg-blue-500 focus:outline-none focus:border-blue-900 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10'>
              Contact Us
            </a>
          </div>

          <div className='mt-3 rounded-md shadow sm:mt-0 sm:ml-3'>
            <button
              onClick={() => setShowPortalModal(true)}
              className='w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-blue-800 bg-white hover:text-blue-700 focus:outline-none focus:border-blue-300 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10'>
              Client Portal
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
