import React from 'react'

export const HomePattern = () => (
  <div className='hidden sm:block sm:absolute sm:inset-y-0 sm:h-full sm:w-full'>
    <div className='relative h-full max-w-screen-xl mx-auto'>
      <svg
        className='absolute right-full transform translate-y-1/4 translate-x-1/4 lg:translate-x-1/2'
        width='404'
        height='784'
        fill='none'
        viewBox='0 0 404 784'>
        <defs>
          <pattern
            id='f210dbf6-a58d-4871-961e-36d5016a0f49'
            x='0'
            y='0'
            width='20'
            height='20'
            patternUnits='userSpaceOnUse'>
            <rect
              x='0'
              y='0'
              width='4'
              height='4'
              className='text-gray-200'
              fill='currentColor'
            />
          </pattern>
        </defs>
        <rect
          width='404'
          height='784'
          fill='url(#f210dbf6-a58d-4871-961e-36d5016a0f49)'
        />
      </svg>
      <svg
        className='absolute left-full transform -translate-y-3/4 -translate-x-1/4 md:-translate-y-1/2 lg:-translate-x-1/2'
        width='404'
        height='784'
        fill='none'
        viewBox='0 0 404 784'>
        <defs>
          <pattern
            id='5d0dd344-b041-4d26-bec4-8d33ea57ec9b'
            x='0'
            y='0'
            width='20'
            height='20'
            patternUnits='userSpaceOnUse'>
            <rect
              x='0'
              y='0'
              width='4'
              height='4'
              className='text-gray-200'
              fill='currentColor'
            />
          </pattern>
        </defs>
        <rect
          width='404'
          height='784'
          fill='url(#5d0dd344-b041-4d26-bec4-8d33ea57ec9b)'
        />
      </svg>
    </div>
  </div>
)
