export const prodEnvironment = {
  production: true,
  firebaseConfig: {
    apiKey: 'AIzaSyDlFxHRbSwv6kjTCprIF7WA7Fz6jSG0Wrc',
    authDomain: 'spinnakerfs.firebaseapp.com',
    databaseURL: 'https://spinnakerfs.firebaseio.com',
    projectId: 'spinnakerfs',
    storageBucket: 'spinnakerfs.appspot.com',
    messagingSenderId: '732718521462',
    appId: '1:732718521462:web:5cf3b5869200ce96e51357',
  },
}

export default prodEnvironment
