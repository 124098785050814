import React, { useState } from 'react'
import { useSpring, animated } from 'react-spring'

interface Props {
  isOpen: boolean
  toggleSidebar: () => void
}

export const MobileSidebar = ({ isOpen, toggleSidebar }: Props) => {
  const sidebarClasses = isOpen
    ? 'duration-100 ease-in scale-100 opacity-100'
    : 'duration-150 ease-out scale-95 opacity-0'

  return (
    <div
      className={`absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden ${sidebarClasses}`}>
      <div className='rounded-lg shadow-md'>
        <div
          className='rounded-lg bg-white shadow-xs overflow-hidden'
          role='menu'
          aria-orientation='vertical'
          aria-labelledby='main-menu'>
          <div className='px-5 pt-4 flex items-center justify-between'>
            <div>
              <img
                className='h-8 w-auto'
                src={require('../../../assets/spinnaker_logo_icon.svg')}
                alt=''
              />
            </div>
            <div className='-mr-2'>
              <button
                type='button'
                onClick={toggleSidebar}
                className='inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out'
                aria-label='Close menu'>
                <svg
                  className='h-6 w-6'
                  stroke='currentColor'
                  fill='none'
                  viewBox='0 0 24 24'>
                  <path
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke-width='2'
                    d='M6 18L18 6M6 6l12 12'
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className='px-2 pt-2 pb-3'>
            <a
              href='/#home'
              className='mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out'
              onClick={toggleSidebar}
              role='menuitem'>
              Home
            </a>

            <a
              href='/#our-services'
              className='mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out'
              onClick={toggleSidebar}
              role='menuitem'>
              Services
            </a>

            <a
              href='/#manage-documents'
              className='mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out'
              onClick={toggleSidebar}
              role='menuitem'>
              Manage Documents
            </a>
            {/* <a
              href='/#resources'
              className='mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out'
              onClick={toggleSidebar}
              role='menuitem'>
              Resources
            </a> */}
          </div>
          {/* <div>
            <a
              href='#'
              onClick={toggleSidebar}
              className='block w-full px-5 py-3 text-center font-medium text-blue-600 bg-gray-50 hover:bg-gray-100 hover:text-blue-700 focus:outline-none focus:bg-gray-100 focus:text-blue-700 transition duration-150 ease-in-out'
              role='menuitem'>
              Client Portal
            </a>
          </div> */}
        </div>
      </div>
    </div>
  )
}
